import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { Layout } from "@solublestudio/gatsby-theme-soluble-source"

import Section from "../sections"

export default function Page({ data, pageContext }) {
  const dark = useMemo(() => {
    const firstSection = data.datoCmsPage.sections?.length
      ? data.datoCmsPage.sections[0]
      : null
    if (firstSection) {
      return (
        ["DatoCmsTitleSection", "DtoCmsTextSection"].indexOf(
          firstSection.__typename
        ) === -1 ||
        (firstSection.__typename === "DatoCmsHeroSectionAlt" &&
          firstSection.backgroundColor)
      )
    }

    return false
  }, [data.datoCmsPage.sections])

  return (
    <Layout
      navbarFixed={true}
      navbarTransparent={true}
      isDark={dark}
      footerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
      }}
      headerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
        url: pageContext?.pageUrl,
      }}
    >
      {data.datoCmsPage.sections.map((section, i) => (
        <Section
          key={i}
          order={i + 1}
          isLast={i + 1 === data.datoCmsPage.sections.length}
          previousSectionType={
            i ? data.datoCmsPage.sections[i - 1].__typename : null
          }
          nextSectionType={
            data.datoCmsPage.sections.length > i + 1
              ? data.datoCmsPage.sections[i + 1].__typename
              : null
          }
          pageSlug={data?.datoCmsPage?.slug}
          {...section}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  fragment HubspotLanding on HubspotLanding {
    title
    description
    slug
    date: updatedAt(formatString: "D MMM YYYY · hh:mm")
    createdAt
    updatedAt
    cta {
      externalLink: href
      slug
      label
    }
    image {
      file {
        publicURL
        childImageSharp {
          resize(width: 300) {
            src
          }
        }
      }
    }
    cardImage: image {
      file {
        publicURL
        childImageSharp {
          fluid(maxHeight: 345, maxWidth: 575) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    language
    kind
  }
  fragment Button on DatoCmsButton {
    link {
      slug
    }
    label
    detached
    externalLink
    hover
    downloadLink {
      file {
        publicURL
      }
      basename
    }
  }
  fragment TGradient on DatoCmsTitleGradient {
    title
    titleTag
    colorPattern
    dark
  }
  fragment EventItem on DatoCmsEvent {
    title
    description
    date(formatString: "D MMM YYYY · hh:mm")
    dateRaw: date
    image {
      file {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cardImageBackup: image {
      file {
        publicURL
        childImageSharp {
          fluid(maxHeight: 345) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cardImage {
      file {
        publicURL
        childImageSharp {
          fluid(maxHeight: 345) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cardImageStyle
    externalLink
  }
  query Page($id: String, $language: String) {
    ...LastUpdates
    datoCmsPage(id: { eq: $id }, locale: { eq: $language }) {
      title
      slug
      treeParent {
        title
        slug
        treeParent {
          title
          slug
          treeParent {
            title
            slug
          }
        }
      }
      sections {
        ... on DatoCmsBeneficsSection {
          ...DatoCmsBeneficsSection
        }
        ... on DatoCmsCtaSection {
          ...DatoCmsCtaSection
        }
        ... on DatoCmsCtaSectionAlt {
          ...DatoCmsCtaSectionAlt
        }
        ... on DatoCmsFeatureSection {
          ...DatoCmsFeatureSection
        }
        ... on DatoCmsFeaturesIconsSection {
          ...DatoCmsFeaturesIconsSection
        }
        ... on DatoCmsHeroSection {
          ...DatoCmsHeroSection
        }
        ... on DatoCmsHeroSectionAlt {
          ...DatoCmsHeroSectionAlt
        }
        ... on DatoCmsInternalHero {
          ...DatoCmsInternalHero
        }
        ... on DatoCmsKnowUsSection {
          ...DatoCmsKnowUsSection
        }
        ... on DatoCmsTextSection {
          ...DatoCmsTextSection
        }
        ... on DatoCmsProcessSection {
          ...DatoCmsProcessSection
        }
        ... on DatoCmsProductSection {
          ...DatoCmsProductSection
        }
        ... on DatoCmsServiceButtonSection {
          ...DatoCmsServiceButtonSection
        }
        ... on DatoCmsStepSection {
          ...DatoCmsStepSection
        }
        ... on DatoCmsTextImageSection {
          ...DatoCmsTextImageSection
        }
        ... on DatoCmsFormSection {
          ...DatoCmsFormSection
        }
        ... on DatoCmsFeatureCategorySection {
          ...DatoCmsFeatureCategorySection
        }
        ... on DatoCmsInfoCardsSection {
          ...DatoCmsInfoCardsSection
        }
        ... on DatoCmsTitleSection {
          ...DatoCmsTitleSection
        }
        ... on DatoCmsIntroTextSection {
          ...DatoCmsIntroTextSection
        }
        ... on DatoCmsIframeSection {
          ...DatoCmsIframeSection
        }
        ... on DatoCmsTextHeroSection {
          ...DatoCmsTextHeroSection
        }
        ... on DatoCmsUseCasesListSection {
          ...DatoCmsUseCasesListSection
        }
        ... on DatoCmsBlogShortSection {
          ...DatoCmsBlogShortSection
        }
        ... on DatoCmsWhitePaperShortSection {
          ...DatoCmsWhitePaperShortSection
        }
        ... on DatoCmsWhitePaperFullSection {
          ...DatoCmsWhitePaperFullSection
        }
        ... on DatoCmsSuccessStoriesShortSection {
          ...DatoCmsSuccessStoriesShortSection
        }
        ... on DatoCmsSuccessStoriesFullSection {
          ...DatoCmsSuccessStoriesFullSection
        }
        ... on DatoCmsCarouselEventsSection {
          ...DatoCmsCarouselEventsSection
        }
        ... on DatoCmsNextEventsSection {
          ...DatoCmsNextEventsSection
        }
        ... on DatoCmsPreviusEventsSection {
          ...DatoCmsPreviusEventsSection
        }
        ... on DatoCmsCtaBannersSection {
          ...DatoCmsCtaBannersSection
        }
        ... on DatoCmsOtherResourcesSection {
          ...DatoCmsOtherResourcesSection
        }
        ... on DatoCmsHighlightedResourceSection {
          ...DatoCmsHighlightedResourceSection
        }
        ... on DatoCmsBlogSection {
          ...BlogSection
        }
        ... on DatoCmsOpenPositionSection {
          ...DatoCmsOpenPositionSection
        }
        ... on DatoCmsQuoteSection {
          ...DatoCmsQuoteSection
        }
        ... on DatoCmsMediaSection {
          ...DatoCmsMediaSection
        }
        ... on DatoCmsWebinarShortSection {
          ...DatoCmsWebinarShortSection
        }
        ... on DatoCmsWebinarFullSection {
          ...DatoCmsWebinarFullSection
        }
        ... on DatoCmsStepsSection {
          ...DatoCmsStepsSection
        }
      }
    }
  }
  fragment LastUpdates on Query {
    lastWhitepaperUpdate: allDatoCmsWhitepaper(
      sort: { fields: meta___updatedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          meta {
            updatedAt
          }
          id
        }
      }
    }
    lastSuccessStoryUpdate: allDatoCmsSuccessStory(
      sort: { fields: meta___updatedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          meta {
            updatedAt
          }
          id
        }
      }
    }
    lastBlogUpdate: allDatoCmsBlog(
      sort: { fields: meta___updatedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          meta {
            updatedAt
          }
          id
        }
      }
    }
    lastEvent: allDatoCmsEvent(
      sort: { fields: meta___updatedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          meta {
            updatedAt
          }
          id
        }
      }
    }
    lastHeaderUpdate: allDatoCmsHeader(
      sort: { fields: meta___updatedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          meta {
            updatedAt
          }
          id
        }
      }
    }
    lastFooterUpdate: allDatoCmsFooter(
      sort: { fields: meta___updatedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          meta {
            updatedAt
          }
          id
        }
      }
    }
  }
`
